<script setup lang="ts">
import { useClient } from "~/stores/client";

const client = useClient();
const router = useRouter();

if (client.isAdmin !== true) {
  router.push('/');
}
</script>

<template>
  <div class="main container-xxl py-24">
    <MetaTags
      :title="'Панель администратора'"
      :description="'Панель администратора'"
    />
    <AppBreadcrumbs
      :links="[
        {
          text: 'Панель администратора'
        }
      ]"
    />
    <h1 class="fw-bold mb-15">
      Панель администратора
    </h1>
    <div class="row">
      <div class="col-3 d-flex flex-column gap-15">
        <NuxtLink
          class="btn btn-primary"
          to="/admin/dashboard"
        >
          Дашборд
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/summaries"
        >
          Первичные ВИ
          <span
            class="badge bg-accent rounded-pill"
          >
            2
          </span>
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/clients"
        >
          Пользователи
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/companies"
        >
          Компании
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/notifications"
        >
          Уведомления
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/help-requests"
        >
          Тех. поддержка
        </NuxtLink>
        <NuxtLink
          class="btn btn-primary"
          to="/admin/complaint"
        >
          Жалобы
        </NuxtLink>
      </div>
      <div class="col-9">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>